import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import * as Yup from 'yup';
import { useAuth } from '../contexts/AuthContext';
import WithGuest from '../components/WithGuest';

// Dynamic imports
const Head = dynamic(() => import('next/head'));
const LogoHeader = dynamic(() => import('../components/LogoHeader'));
const Alert = dynamic(() => import('@mui/material/Alert'));
const Card = dynamic(() => import('@mui/material/Card'));
const CardContent = dynamic(() => import('@mui/material/CardContent'));
const Container = dynamic(() => import('@mui/material/Container'));
const Button = dynamic(() => import('@mui/material/Button'));
const Box = dynamic(() => import('@mui/material/Box'));
const TextField = dynamic(() => import('@mui/material/TextField'));
const Formik = dynamic(async () => {
  const module = await import('formik');

  return module.Formik;
});
const Form = dynamic(async () => {
  const module = await import('formik');

  return module.Form;
});

const signupSchema = Yup.object().shape({
  email: Yup.string()
    .trim('Please remove any leading or trailing whitespace')
    .email('Invalid email address')
    .required('Required'),
  password: Yup.string()
    .trim('Please remove any leading or trailing whitespace')
    .min(8, 'Password must be at least 8 characters')
    .required('Required'),
});

const logLoginEvent = async (label = 'login', email = '') => {
  const [{ logEvent }, { firebaseAnalytics }] = await Promise.all([
    import('firebase/analytics'),
    import('../utilities/firebase'),
  ]);

  if (firebaseAnalytics) {
    logEvent(firebaseAnalytics, label, {
      method: 'Firebase - Email and Password',
      function: 'signInWithEmailAndPassword',
      ...(email ? { username: email } : {}),
    });
  }
};

const Login = () => {
  const { login } = useAuth() || {};

  const [errorMessage, setErrorMessage] = useState('');

  return (
    <>
      <Head>
        <title>Login - CryptoTendies Notification PWA</title>
      </Head>
      <Container maxWidth="sm">
        <Card variant="outlined" sx={{ mt: 6 }}>
          <CardContent>
            {errorMessage && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {errorMessage}
              </Alert>
            )}
            <LogoHeader title="Login" />
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={signupSchema}
              onSubmit={async (values, { setSubmitting }) => {
                setErrorMessage('');

                try {
                  if (!login) {
                    throw new Error(
                      'The `login` AuthContext method is not defined.',
                    );
                  }

                  // The `WithGuest` guard will automatically redirect after a successful login
                  await login(values.email, values.password);

                  logLoginEvent();
                } catch (error) {
                  // See https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
                  switch (error.code) {
                    case 'auth/user-disabled':
                      setErrorMessage('This account has been disabled.');
                      break;
                    case 'auth/user-not-found':
                    case 'auth/wrong-password':
                      setErrorMessage('The credentials entered are not valid.');
                      break;
                    case 'auth/operation-not-allowed':
                      setErrorMessage(
                        'Account authentication is disabled for this application.',
                      );
                      break;
                    default:
                      setErrorMessage(
                        'An unexpected error has occurred. Please try again.',
                      );
                      break;
                  }

                  logLoginEvent('login_attempted', values.email);

                  setSubmitting(false);
                }
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <TextField
                    fullWidth
                    label="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    autoFocus
                    sx={{ mt: 3 }}
                  />
                  <TextField
                    fullWidth
                    label="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                    sx={{ mt: 3 }}
                  />
                  <Box sx={{ mt: 3, mb: 2, mr: 0 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                      disableElevation
                    >
                      Login
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default WithGuest(Login);
