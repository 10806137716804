import React from 'react';
import { useRouter } from 'next/router';
import { useAuth } from '../contexts/AuthContext';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const WithGuest = Component => {
  const Guest = props => {
    const router = useRouter();
    const { user } = useAuth();

    if (user) {
      router.push('/');

      return null;
    }

    return <Component {...props} />;
  };

  // Copy in getInitialProps data
  if (Component.getInitialProps) {
    Guest.getInitialProps = Component.getInitialProps;
  }

  return Guest;
};

export default WithGuest;
